<mat-card>
    <mat-card-content>
      <mat-grid-list cols="24" gutterSize="16px" rowHeight="30px">
          <mat-grid-tile class="header" [colspan]="11">
            <div>Name</div>
          </mat-grid-tile>
          <mat-grid-tile class="header" [colspan]="11">
            <div>Value</div>
          </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="24" gutterSize="16px" rowHeight="50px">
          <ng-container *ngIf="!labels.controls.length">
            <mat-grid-tile [colspan]="24">
              <div class="text-center">No Data</div>
            </mat-grid-tile>
          </ng-container>
          <ng-container *ngFor="let label of labels?.controls; let i = index">
            <ng-container *ngIf="label.enabled && !readOnly" [formGroup]="label">
                <!-- <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px"> -->
              <mat-grid-tile [colspan]="11">
                  <mat-form-field class="w-100 small" floatLabel="never">
                      <mat-select matInput formControlName="name" [placeholder]="readOnly ? '' : 'Select a name'"
                      name="name" [attr.disabled]="readOnly" [required]="!!label?.value?.value">
                          <mat-option *ngFor="let name of getAllLabelNames()" [value]="name">
                              {{ name }}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="label.controls['name'].hasError('required')">
                        You must select a name
                      </mat-error>
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="11">
                  <mat-form-field class="w-100 small" floatLabel="never">
                      <mat-select matInput formControlName="value" [placeholder]="readOnly ? '' : 'Select a value'"
                      name="value" [attr.disabled]="readOnly" [required]="!!label?.value?.name">
                          <mat-option *ngFor="let value of getValuesByLabel(label.controls['name'].value)" [value]="value">
                              {{ value }}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="label.controls['value'].hasError('required')">
                        You must select a value
                      </mat-error>
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="2">
                  <div class="w-100 text-right">
                      <button *ngIf="!readOnly" (click)="removeLabel(i)" class="mat-elevation-z1 small"
                          color="warn" mat-mini-fab style="margin-right: 14px">
                          <mat-icon>remove_circle_outline</mat-icon>
                      </button>
                  </div>
              </mat-grid-tile>
          <!-- </mat-grid-list> -->
            </ng-container>
            <ng-container *ngIf="readOnly">
              <mat-grid-tile [colspan]="11">
                <div class="w-100 small">{{ label.value.name || '-' }}</div>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="11">
                <div class="w-100 small">{{ label.value.value || '-' }}</div>
              </mat-grid-tile>
            </ng-container>
          </ng-container>
          <mat-grid-tile *ngIf="!readOnly" [colspan]="24">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="addLabel()" type="button">
                <mat-icon>add</mat-icon>
                Add labels
            </button>
          </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
</mat-card>