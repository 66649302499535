import {Observable} from 'rxjs';
import {CustomDataSource, Page} from './CustomDataSource';
import {map} from 'rxjs/operators';
import { DeviceService } from '../services/devices.service';

export class SearchDeviceDataSource extends CustomDataSource<Device> {

  constructor(private deviceService: DeviceService) {
    super();
  }

  loadData(search: any, page: number, size: number, sortField: string, sortDir: string, options: any): Observable<Page<Device>> {
    let observable: Observable<any>;
    if (search) {
      observable = this.deviceService.searchDevices(search, page, size, sortField, sortDir);
    } 
    else if (options.filter) {
      observable = this.deviceService.filterDevicesWithoutSites(page, size, sortField, sortDir);
    }
    else {
      observable = this.deviceService.getAllDevices(page, size, sortField, sortDir);
    }
    return observable.pipe(map(res => ({data: res.devices, total: res.total})));
  }
}

export interface Device {
    uuid: string,
    id: string;
    name: string;
    parentId: string;
    manufacturer: string;
    model: string;
    zoneName: string;
    floorName: string;
    status: string;
  }
