import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-labels-sites',
  templateUrl: './labels-sites.component.html',
  styleUrls: ['./labels-sites.component.scss']
})
export class LabelsSitesComponent implements OnInit {

  @Input() readOnly;
  @Input() field;
  @Input() labelsFromBu;

  constructor(private formGroupDirective: FormGroupDirective, private fb: UntypedFormBuilder) {
  }

  get labels(): UntypedFormArray {
    return (this.formGroupDirective.form?.get(this.field) as UntypedFormArray) ?? this.fb.array([]);
  }

  ngOnInit(): void {
    if (!this.readOnly && !this.labels?.length) {
      this.addLabel();
    }
  }

  addLabel(): void {
    const labelObj = this.fb.group({
      name: [''],
      value: [''],
    });
    this.labels.push(labelObj);
  }

  removeLabel(index: number): void {
    this.labels.removeAt(index);
  }

  getValuesByLabel(labelName: string) : any{
    const selectedObj = this.labelsFromBu?.find(obj => obj?.name === labelName);
    return selectedObj ? selectedObj?.values : [];
  }

  getAllLabelNames() : any{
    return this.labelsFromBu?.map(item => item?.name);
  }
}